import mainRefs from "@/__main__/refs.mjs";
import GameSharkOverlay from "@/feature-gameshark/GameSharkOverlay.jsx";
import mapOriginalRefs from "@/util/map-original-refs.mjs";

const original = mapOriginalRefs({
  mainRefsComponents: mainRefs.components,
});

export function setup() {
  original.append({
    mainRefsComponents: {
      floatingElementsTop: [GameSharkOverlay],
    },
  });
}

export function teardown() {
  original.restore();
}
